<template>
  <div class="top-content">
    <el-select @change="handleShopChange" v-model="ruleForm.shopId" class="m-2" placeholder="统计范围" size="large">
      <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId" />
    </el-select>

    <el-select v-model="ruleForm.empId" filterable class="m-2" placeholder="选择理疗师" size="large">
      <el-option v-for="item in teacherList" :key="item.userId" :label="item.userRealname" :value="item.userId" />
    </el-select>

    <el-date-picker @change="handleDateChange" v-model="ruleForm.date" value-format="YYYY-MM-DD" type="daterange" range-separator="到"
      start-placeholder="开始时间" end-placeholder="结束时间" />

    <div class="search-wrap">
      <el-button class="add-staff" type="primary" @click="handleSearchClick">筛选</el-button>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, onUnmounted } from 'vue'
import { timeType, showState, starList } from '../../config'
import { getBelongShop } from '@/service/main/staff'
import { COMPANY_ID } from '@/common/constant'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
import { getShopList } from '@/service/main/staff'
import { getCommentList } from '@/service/main/order'
import store from '@/store'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {
    queryInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props) {

    const ruleForm = reactive({
      shopId: '',
      date: '',
      empId: '',
      startDate: "",
      endDate: "",
      current: 1,
      size: 10
    })


    watch(() => props.queryInfo, (n) => {
      ruleForm.current = n.current
      ruleForm.size = n.size
      getCommentListFunc()
    }, {
      deep: true
    })

    const handleDateChange = value => {
      if (value) {
        ruleForm.startDate = value[0]
        ruleForm.endDate = value[1]
      }else{
        ruleForm.startDate = undefined
        ruleForm.endDate = undefined
      }
    }

    const handleShopChange = shopid => {
      cache.setCache('commentShopid', shopid)
      getTeacherList(shopid)
      ruleForm.empId = ''
    }

    const getTeacherList = async (shopId) => {
      const teacherRes = await getShopList(shopId)
      teacherList.value = teacherRes.data.list
    }

    const handleTimeTypeChange = value => {
      cache.setCache('commentTimeType', value)
      ruleForm.date = ''
      cache.setCache('commentStart', "")
      cache.setCache('commentEnd', "")
    }

    const teacherList = ref([])
    const shopList = ref([])

    const getCommentListFunc = async () => {
      store.dispatch('customer/getUsetCommrntListAction', { ...ruleForm })
    }

    const handleSearchClick = () => {
      ruleForm.current = 1
      getCommentListFunc()
    }

    emitter.on('refreshOrderCommentPage', () => {
      getCommentListFunc()
    })

    onUnmounted(() => {
      emitter.off('refreshOrderCommentPage')
    })

    const initPage = async () => {
      const res = await getBelongShop(COMPANY_ID)
      let list = res.data.list
      list.unshift({
        shopName: "总部",
        shopId: "0"
      })
      shopList.value = res.data.list
      let tichengShopid = cache.getCache('commentShopid')

      if (String(tichengShopid).length == 0) {
        cache.setCache('commentShopid', '0')
        ruleForm.shopId = 0
      } else {
        ruleForm.shopId = tichengShopid
      }

      getTeacherList(ruleForm.shopId)
      getCommentListFunc()
    }
    initPage()

    return {
      ruleForm,
      shopList,
      timeType,
      handleTimeTypeChange,
      handleDateChange,
      COMPANY_ID,
      handleShopChange,
      showState,
      starList,
      teacherList,
      handleSearchClick

    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .search-wrap {
    margin-left: 10px;
  }

  &:deep(.el-select) {
    margin-right: 10px;
  }

}
</style>




