<template>
  <div class="comment-list">
    <el-table :data="dataList" border style="width: 100%">
      <el-table-column align="center" prop="orderNo" label="订单编号" width="205" />
      <el-table-column align="center" prop="appTime" label="预约时间" width="165" />
      <el-table-column align="center" prop="userName" label="用户信息" width="120" />
      <el-table-column align="center" prop="evaluateTime" label="评价时间" width="165" />
      <el-table-column align="center" prop="shopName" label="评价门店" width="170" />
      <el-table-column align="center" prop="shopScore" label="评价分值" width="150">
        <template #default="scope">
          <el-rate disabled v-model="scope.row.shopScore" size="large" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="shopEvaluate" label="评价内容" width="250" />
      <el-table-column align="center" prop="empName" label="评价技师" width="120" />
      <el-table-column align="center" prop="score" label="评价分值" width="150">
        <template #default="scope">
          <el-rate disabled v-model="scope.row.score" size="large" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="empEvaluate" label="评价内容" width="250" />
      <el-table-column align="center" prop="itemList" label="评价服务" width="190">
        <template #default="scope">
          <div class="borders" v-for="items in scope.row.itemList" :key="items">
            <p>{{ items.serveName }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="itemList" label="评价分值" width="150">
        <template #default="scope">
          <div class="borders" v-for="items in scope.row.itemList" :key="items">
            <el-rate disabled v-model="scope.row.score" size="large" />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="itemList" label="评价内容" width="250">
        <template #default="scope">
          <div class="borders" v-for="items in scope.row.itemList" :key="items">
            <p>{{ items.content }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="pictureList" label="图片" width="200">
        <template #default="scope">
          <el-image
          v-for="items in scope.row.pictureList" 
          :key="items" 
          style="width: 50px; height: 50px;margin-right: 5px;"
          :src="items"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="scope.row.pictureList"
          :initial-index="4"
          fit="cover"
        />
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" align="center" fixed="right" width="150px" v-has="'evaluate:edit'">
          <template #default="scope">
            <el-switch v-model="scope.row.display" @change="handlleSwichClick(scope.row)" />
          </template>
        </el-table-column>
    </el-table>
    <el-pagination v-model:currentPage="queryInfo.current" v-model:page-size="queryInfo.size"
    :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from '../../config'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
import { displayEvaluate } from '@/service/main/order'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup(props, { emit }) {
    const store = useStore()


    const queryInfo = reactive({
      current: 1,
      size: 10,

    })

    const handleSizeChange = size => {
      queryInfo.size = size
      emit('changePagesize',size)
    }

    const handleCurrentChange = page => {
      queryInfo.current = page
      emit('changePage',page)
    }

    const dataCount = computed(() => store.state.customer.userCommentListCount)
    const dataList = computed(() => store.state.customer.userCommentList)


    const handlleSwichClick = async (item) => {
      let { id } = item
      const res = await displayEvaluate(id)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        emitter.emit('refreshOrderCommentPage')
      }
    }

    const active = ref('1')
    const inactiv = ref('0')

    return {
      active,
      inactiv,
      handlleSwichClick,
      contentTableConfig,
      dataList,
      queryInfo,
      dataCount,
      handleSizeChange,
      handleCurrentChange,
    }

  }
})
</script>

<style scoped lang="less">
.comment-list {
  .handle-btns {
    position: relative;
  }
  &:deep(.el-table) {
    th.el-table__cell{
      background-color: rgba(239, 239, 239, 1) !important;
      .cell {
        color: rgba(80, 80, 80, 1) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
    .el-table__header-wrapper {
    
    .el-table__cell {

      background-color: rgba(239, 239, 239, 1);
      // padding: 9px 0 !important;
      // border:1px solid rgb(223, 223, 223);

      .cell {
        color: rgba(80, 80, 80, 1) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }

  }
    .cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .borders{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #ebeef5;
    align-items: center;
    height: 60px;
    // line-height: 40px;
    padding: 10px !important;
    // p{
    //   border-bottom: 1px solid #ebeef5;
    //   line-height: 28px;
    // }
    // p:last-child{
    //   border: none !important;
    // }
  }
  .borders:last-child{
      border: none !important;
  }
}
</style>




