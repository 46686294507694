<template>
  <div class="comment">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <topContent :queryInfo="queryInfo"></topContent>
      <commentList @changePage="changePage" @changePagesize="changePagesize"></commentList>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import topContent from './cpns/top-content/top-content.vue';
import { breadcrumbList } from './config'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import commentList from './cpns/comment-list/comment-list.vue'

export default defineComponent({
  props: {

  },
  components: {
    topContent,
    Breadcrumb,
    commentList
  },
  setup() {

    const queryInfo = reactive({
      current: 1,
      size: 10
    })

    const changePagesize = pagesize => {
      queryInfo.size = pagesize
    }

    const changePage = page => {
      queryInfo.current = page
    }

    return {
      changePage,
      changePagesize,
      breadcrumbList,
      queryInfo
    }

  }
})
</script>

<style scoped lang="less">
.comment {
  .main-content {
    background-color: #fff;
    padding: 20px;
    margin: 20px;
    min-width: 1080px;
  }
}
</style>




