export const breadcrumbList = [
  {
    name: '订单管理',
    path: ''
  },
  {
    name: '评价列表',
    path: ''
  }
]

export const contentTableConfig = {
  propList: [
    {
      prop: 'shopName',
      label: '门店',
      minWidth: '150',
      slotName: 'shopName'
    },
    { prop: 'userRealname', label: '客户', minWidth: '150' },
    {
      prop: 'orderNo',
      label: '订单编号',
      minWidth: '140',
      slotName: 'orderNo'
    },
    {
      prop: 'goodsName',
      label: '商品名称',
      minWidth: '170',
      slotName: 'goodsName'
    },
    {
      prop: 'rater',
      label: '评价星级',
      minWidth: '140',
      slotName: 'rater'
    },
    {
      prop: 'comment',
      label: '评论内容',
      minWidth: '120',
      slotName: 'comment'
    },
    {
      prop: 'createTime',
      label: '评论时间',
      minWidth: '120',
      slotName: 'createTime'
    },
    {
      prop: 'empName',
      label: '理疗师/销售',
      minWidth: '120',
      slotName: 'empName'
    },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: true,
  showSelectColumn: true
}

export const timeType = [
  {
    label: '日报',
    value: 0
  },
  {
    label: '月报',
    value: 1
  }
]

export const showState = [
  {
    label: '全部',
    value: 3
  },
  {
    label: '显示',
    value: 1
  },
  {
    label: '隐藏',
    value: 0
  }
]

export const starList = [
  {
    label: '全部',
    value: 0
  },
  {
    label: '1星',
    value: 1
  },
  {
    label: '2星',
    value: 2
  },
  {
    label: '3星',
    value: 3
  },
  {
    label: '4星',
    value: 4
  },
  {
    label: '5星',
    value: 5
  }
]
